<template>
    <div id="Setting">
        <div class="common-header">
            <i class="el-icon-arrow-left visit-back" @click="()=>{this.$router.go(-1)}"></i>
            <span>{{this.$route.query.researchName}}</span>
        </div>
        <div class="setting-content">
            <el-card shadow="hover" :body-style="{padding:'5px'}">
                <el-collapse v-model="activeNames">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <span class="content_coll_title">研究信息</span>
                            <i class="iconfont icon-bianji" style="margin: 4px 0 0 10px;color:#2c5c89;"
                            @click.stop="editResearch"></i>
                        </template>
                        <div class="research-data">
                            <span class="research-data_title">研究标题:</span>
                            <span class="research-data_detail">{{researchData.researchName}}</span>
                            <span class="research-data_title">研究编号:</span>
                            <span class="research-data_detail">{{researchData.code}}</span>
                            <span class="research-data_title">研究摘要:</span>
                            <span class="research-data_detail">{{researchData.researchTarget}}</span>
                            <span class="research-data_title">研究类型:</span>
                            <span class="research-data_detail">{{researchData.researchType === '0' ? '干预型' : '观察型'}}</span>
                            <span class="research-data_title">样本量:</span>
                            <span class="research-data_detail">{{researchData.expectCaseNum}}（达到样本量后允许继续添加受试者）</span>
                            <span class="research-data_title">研究周期:</span>
                            <span class="research-data_detail">{{researchData.startDate.replace(/\-/g,'/').split(' ')[0]+'-'+researchData.endDate.replace(/\-/g,'/').split(' ')[0]}}</span>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            <span class="content_coll_title">受试者信息</span>
                            <el-button class="content_coll_add" size="mini" type="primary" @click.stop="updateVisitCon">
                                更新设置
                            </el-button>
                        </template>
                        <el-form class="visitForm" ref="visitForm" :model="visitForm" :rules="visitFormRules"
                                label-width="100px" size="mini">
                            <el-form-item label="受试者编号:">
                                <el-radio-group v-model="visitForm.createFlag">
                                    <el-radio :label="0">手动生成</el-radio>
                                    <el-radio :label="1">系统生成</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-row v-show="visitForm.createFlag===1" :gutter="1">
                                <el-col :span="6">
                                    <el-form-item label="生成方案:" prop="prefix">
                                        <el-input v-model="visitForm.prefix" @input="generateCode" minlength="1" maxlength="14"
                                                style="width: 100%" placeholder="请输入前缀，可输入${siteid}引用中心编号">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" :pull="2" :offset="1">
                                    <el-form-item label="">
                                        <el-select v-model="visitForm.codeFlag" @change="generateCode" placeholder="请选择" style="width: 100%">
                                            <el-option label="顺序" value="0"></el-option>
                                            <el-option label="随机" value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" :pull="4" :offset="1">
                                    <el-form-item label="" prop="placeholder">
                                        <el-input minlength="1" maxlength="9" @input="generateCode" v-model="visitForm.placeholder"
                                                style="width: 100%" placeholder="输入“0”占位"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2" :pull="2">
                                    <span style="color:#0E84D8;">例：{{ visitForm.code }}</span>
                                </el-col>
                            </el-row>
                            <el-form-item label="其他信息:">
                                <el-checkbox-group style="display:inline-block;" v-model="visitForm.otherMessage">
                                    <el-checkbox v-for="item in visitForm.lable" :key="item.pCode" :label="item.pCode">
                                        {{item.pName}}
                                    </el-checkbox>
                                </el-checkbox-group>
                                <span class="clearCheck" @click="clearCheckBtn">清空</span>
                            </el-form-item>
                            <el-form-item label="受试者分组:">
                                <ul class="visitGroup">
                                    <li v-for="(item,index) in visitGroup" :key="index">
                                        <span>{{item.groupName+'（'+item.data.reduce(function(total,item,index){return total+item.dictName+','},'').replace(/,$/gi,"")+'）'}}</span>
                                        <!-- <i @click="deleteGroupItem(index)" style="margin-left:10px;color:#787878;" class="el-icon-delete"></i> -->
                                        <el-popconfirm title="您确定要删除该受试者分组吗？" @confirm="deleteGroupItem(index)">
                                            <i slot="reference" style="margin-left:10px;color:#787878;"
                                            class="el-icon-delete"></i>
                                        </el-popconfirm>
                                    </li>
                                    <li>
                                        <el-popover placement="top" width="280" v-model="visitGroupVisible"
                                                    @show="showAddGroup">
                                            <h5 style="margin-bottom:10px;">受试者分组</h5>
                                            <el-form ref="addGroupForm" :model="addGroup" :rules="addGroupRules"
                                                    label-width="90px"
                                                    size="mini">
                                                <el-form-item label="分组标签:" prop="groupName">
                                                    <el-input v-model="addGroup.groupName"></el-input>
                                                </el-form-item>
                                                <el-form-item v-for="(addItem,addIndex) in addGroup.data" :key="addIndex"
                                                            :rules="addGroupRules.dictName"
                                                            :prop="'data.' + addIndex + '.dictName'"
                                                            :label="addIndex===0?'组别:':''">

                                                    <el-input style="width:70%;"
                                                            v-model="addGroup.data[addIndex].dictName"></el-input>
                                                    <i v-show="addIndex===0" @click="addGroupItem"
                                                    style="margin-left:10px;color:#787878;"
                                                    class="el-icon-circle-plus-outline"></i>
                                                    <i @click="deleteGroupValItem(addIndex)"
                                                    style="margin-left:10px;color:#787878;" class="el-icon-delete"></i>
                                                </el-form-item>
                                            </el-form>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="text" @click="visitGroupVisible = false">取消
                                                </el-button>
                                                <el-button type="primary" size="mini" @click="addGroupCon">保存</el-button>
                                            </div>
                                            <i class="el-icon-circle-plus-outline" slot="reference">&nbsp;&nbsp;&nbsp;添加受试者分组</i>
                                        </el-popover>
                                    </li>
                                </ul>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                     <el-collapse-item name="3">
                        <template slot="title">
                            <span class="content_coll_title">微信服务号</span>
                            <el-button class="content_coll_add" size="mini" type="primary" @click.stop="saveWXseting">更新设置</el-button>
                        </template>
                        <el-form class="wxForm" label-width="100px" size="mini">
                            <el-form-item label="是否启用:">
                                <el-switch
                                    v-model="wxForm.enable"
                                    active-color="#13ce66"
                                    inactive-color="#cccccc">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                    <el-collapse-item name="4">
                        <template slot="title">
                            <span class="content_coll_title">招募中心</span>
                            <el-button class="content_coll_add" size="mini" type="primary" @click.stop="saveRecru">
                                更新设置
                            </el-button>
                            <!-- <el-button class="content_coll_add" size="mini" type="primary" @click.stop="">添加角色</el-button> -->
                        </template>
                        <el-form class="visitForm" label-width="100px" size="mini">
                            <el-form-item label="是否启用:">
                                <el-switch
                                    v-model="enable"
                                    active-color="#13ce66"
                                    inactive-color="#cccccc">
                                </el-switch>
                                <span style="margin-left:40px;cursor:pointer" v-if="enable" @click="getCode">招募二维码<i class="el-icon-full-screen" style="margin-left:10px"></i></span>
                            </el-form-item>
                            <el-form-item label="预筛信息:">
                                <el-checkbox-group style="display:inline-block;" v-model="infoCheckbox">
                                    <el-checkbox v-for="item in visitForm.lable" :key="item.pCode" :label="item.pName">
                                        {{item.pName}}
                                    </el-checkbox>
                                </el-checkbox-group>
                                <span class="clearCheck" @click="clearCheckInfoBtn">清空</span>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
                <!-- 研究信息修改 -->
            <el-dialog title="研究信息修改" :visible.sync="researchDialogVisible" width="40%" custom-class="center-dialog" top="25vh">
                <el-form size="mini" ref="researchForm" :model="researchForm" :rules="researchFormRules"
                        label-width="80px">
                    <el-form-item label="研究标题" prop="researchName">
                        <el-input v-model="researchForm.researchName"></el-input>
                    </el-form-item>
                    <el-form-item label="研究编号" prop="code">
                        <el-input v-model="researchForm.code"></el-input>
                    </el-form-item>
                    <el-form-item label="研究摘要" prop="researchTarget">
                        <el-input type="textarea" v-model="researchForm.researchTarget"></el-input>
                    </el-form-item>
                    <el-form-item label="研究类型" prop="researchType">
                        <el-select :style="{'width':'100%'}" v-model="researchForm.researchType" placeholder="请选择研究类型">
                            <el-option label="干预型" value="0"></el-option>
                            <el-option label="观察型" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="样本量" prop="expectCaseNum">
                        <el-input type="number" v-model="researchForm.expectCaseNum"></el-input>
                    </el-form-item>
                    <el-form-item label="研究周期" prop="startDate">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="researchForm.startDate" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col :span="2" :style="{'text-align':'center','color':'#c4c4c4'}">——</el-col>
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="researchForm.endDate" style="width: 100%;"></el-date-picker>
                        </el-col>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="researchDialogVisible = false">取 消</el-button>
                    <el-button size="mini" type="primary" @click="researchEditConfirm('researchForm')">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 招募二维码 -->
            <el-dialog title="邀请码" :visible.sync="showCode" width="28%" custom-class="visit-dialog"><!--:rules="visitRulesAdd"-->
                <div id="qrcode" style="width:200px;height:200px"></div>
            </el-dialog>
            </div>
    </div>
</template>

<script>
    import {updateResearch, updateVisit, getVisit, addVisitGroup, deleteVisitGroup,getQrCode,getInfo,saveRecruit} from "@/api/setting";
    import QRCode from 'qrcodejs2'
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    export default {
        name: 'Setting',
        components: {
            // HelloWorld
        },
        data() {
            //自改研究时，时间区间校验处理（prop只支持绑定一个String）
            const timeSection = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入开始时间'));
                } else if (!this.researchForm.endDate) {
                    return callback(new Error('请输入结束时间'));
                } else if (this.researchForm.endDate <= value) {
                    return callback(new Error('结束时间需大于开始时间'));
                } else {
                    callback();
                }
            };
            let amountValid = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入样本量!'));
                } else if (value > 99999) {
                    callback(new Error('样本量最大为99999!'));
                } else
                    callback();
            };
            return {
                wxForm:{
                    enable: false
                },
                submitButtonDisabled: false,
                activeNames: [],
                researchData: {},
                researchForm: {},
                checkbox:[],
                isShowCode:false,
                showCode:false,
                codeUrl:'',
                enable: false,
                infoCheckbox:[],
                researchFormRules: {
                    researchName: [
                        {required: true, message: '请输入研究标题', trigger: 'change,blur'},
                        {min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'change,blur'}
                    ],
                    code: [
                        {required: true, message: '请输入研究编号', trigger: 'change,blur'},
                        {pattern: /^[A-Za-z0-9]{1,12}$/, message: '请输入 12 位以内数字或字母', trigger: 'change,blur'}
                    ],
                    researchTarget: [
                        {required: true, message: '请输入研究摘要', trigger: 'change,blur'},
                        {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change,blur'}
                    ],
                    researchType: [{required: true, message: '请选择研究类型'}],
                    expectCaseNum: [
                        {required: true, message: '请输入样本量', trigger: 'change,blur'},
                        {validator: amountValid, trigger: 'change,blur'},
                    ],
                    startDate: [{required: true, validator: timeSection, trigger: 'change,blur'}],
                },
                researchDialogVisible: false,
                visitForm: {
                    createFlag: 0,
                    otherMessage: [],
                    prefix: '',
                    codeFlag: '0',
                    placeholder: '',
                    lable: [],
                    code:'100010'
                },
                visitFormRules: {
                    prefix: [
                        {min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'change,blur'},
//                      {pattern: /^[0-9a-zA-Z]{1,12}$/, message: '只允许输入字母和数字', trigger: 'change,blur'}
                    ],
                    placeholder: [
                        {pattern: /^[0]{1,9}$/, message: '只允许输入数字0', trigger: 'change,blur'},
                        {min: 1, max: 9, message: '长度在 1 到 9 个字符', trigger: 'change,blur'}
                    ],
                },
                visitGroup: [
                    // {name:'年龄组',data:['65岁以下','65岁以上']},
                    // {name:'治疗组',data:['低剂量','中剂量','高剂量']},
                    // {name:'NYHA心功能分级',data:['I级','II级','III级','IV级']}
                ],
                visitGroupVisible: false,
                addGroup: {
                    groupName: '',
                    data: [{code: '', dictName: ''}]
                },
                addGroupRules: {
                    groupName: [
                        {min: 1, max: 25, message: '长度在 1 到 25 个字符', trigger: 'change,blur'}
                    ],
                    dictName: [
                        {min: 1, max: 25, message: '长度在 1 到 25 个字符', trigger: 'change,blur'}
                    ],
                },
            }
        },
        created() {
            console.log(this.$route.query);
            //初始数据源
            this.$localData('set', 'operatingEnv', 'test');
            this.researchData = Object.assign({}, this.$route.query);
            this.getVisitInfo();
        },
        methods: {
            saveWXseting(){
                console.log(this.wxForm);
            },
            // 保存招募信息
            saveRecru(){    
                let configList = []
                this.infoCheckbox.forEach( item =>{
                    const detail = this.checkbox.find( val => item == val.previewMessages)
                    if(detail){
                        configList.push(
                            {
                                pkId:detail.pkId,
                                previewMessages:detail.previewMessages
                            }
                       )
                    }else{
                        configList.push(
                            {
                                pkId:null,
                                previewMessages:item
                            }
                       )
                    }
                })
                const data = {
                    enable:this.enable,
                    researchCode: this.$route.query.researchCode,
                    configList,
                }
                saveRecruit(data).then( res => {
                    this.$message.success('保存成功')
                }).catch( error => {

                })

            },
            //获取招募信息
            getRecruitInfo(){
                const {researchCode} = this.$route.query
                getInfo(researchCode).then( res => {
                    console.log(res)
                    this.enable = res.data.enable|| false
                    this.checkbox = res.data.configList? res.data.configList:[]
                    this.infoCheckbox =  (res.data.configList||[]).map( item => item.previewMessages)
                }).catch( error => {
                    console.log(error);
                    this.$message.error('获取信息失败，请尝试刷新页面')
                })
            },
            //获取招募二维码
            getCode(){
                const {researchCode} = this.$route.query
                this.showCode = true
                getQrCode(researchCode).then( res => {
                    this.codeUrl = res.data
                    this.code()
                }).catch( error => {
                    this.$message.error('获取验证码失败，请重试')
                })
            },
            code() {
                this.$nextTick(()=>{
                    let qr = document.getElementById('qrcode')
                    document.getElementById("qrcode").innerHTML = "";
                    let qrCode = new QRCode(qr, {
                        width: 200,  
                        height: 200,
                        text:this.codeUrl,
                        colorDark : "#000",
                        colorLight : "#fff",
                    })
                })
            },
            //受试者信息
            getVisitInfo() {
                getVisit({researchCode: this.$route.query.researchCode})
                    .then(res => {
                        res.data.groupMessage != null && (this.visitGroup = res.data.groupMessage);
                        for (const key in this.visitForm) {
                            res.data[key] != null && (this.visitForm[key] = res.data[key])
                        }
                    })
                    .catch(error => {
                        this.submitButtonDisabled = false;
                    });
            },
            //编辑受试者
            updateVisitCon() {
                this.$refs['visitForm'].validate((valid) => {
                    console.log('设置验证', valid);
                    if (valid) {
                        const postData = {
                            researchCode: this.$route.query.researchCode,
                            createFlag: this.visitForm.createFlag,
                            prefix: this.visitForm.prefix,
                            codeFlag: this.visitForm.codeFlag,
                            placeholder: this.visitForm.placeholder,
                            otherMessage: this.visitForm.otherMessage.sort(),
                            // groupMessage: JSON.stringify(this.visitGroup),
                        };
                        updateVisit(postData)
                            .then(res => {
                                this.$message({
                                    type: 'success',
                                    showClose: true,
                                    message: res.data,
                                    center: true
                                })
                                // this.researchData = JSON.parse(JSON.stringify(this.researchForm));
                                // this.researchDialogVisible = false;
                                // this.$router.replace({ name: 'Setting',query: this.researchForm});
                            })
                            .catch(error => {
                                this.submitButtonDisabled = false;
                            });
                    }
                });
            },
            editVisit() {
                console.log(this.visitForm, this.visitGroup)
            },
            addGroupCon() {
                this.$refs['addGroupForm'].validate((valid) => {
                    if (valid) {
                        this.addGroup.data = this.addGroup.data.filter(_ => _.dictName);
                        const postData = {
                            researchCode: this.$route.query.researchCode,
                            groupName: this.addGroup.groupName,
                            dictNameList: this.addGroup.data.reduce(function (total, item) {
                                total.push(item.dictName);
                                return total
                            }, []),
                        };
                        this.addGroup.groupName && this.addGroup.data.length > 0 ?
                            addVisitGroup(postData)
                                .then(res => {
                                    this.visitGroup.push(this.addGroup);
                                    // this.getVisitInfo();
                                    this.visitGroupVisible = false;
                                })
                                .catch(error => {

                                }) :
                            this.visitGroupVisible = false;
                    }
                })
            },
            addGroupItem() {
                this.addGroup.data.push({code: '', dictName: ''});
            },
            deleteGroupItem(index) {
                console.log(this.visitGroup[index]);
                const postData = {
                    researchCode: this.$route.query.researchCode,
                    groupName: this.visitGroup[index].groupName
                };
                deleteVisitGroup(postData)
                    .then(res => {
                        console.log('deleteVisitGroup-----------', res);
                        this.visitGroup.splice(index, 1);
                    })
                    .catch(error => {

                    });
            },
            deleteGroupValItem(index) {
                this.addGroup.data.splice(index, 1);
            },
            showAddGroup() {
                this.addGroup = this.$options.data.call(this).addGroup;
            },
            clearCheckBtn() {
                this.visitForm.otherMessage = [];
            },
            clearCheckInfoBtn(){
                this.infoCheckbox = [];
            },
            editResearch() {
                this.researchForm = JSON.parse(JSON.stringify(this.researchData));
                this.researchDialogVisible = true;
            },
            researchEditConfirm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submitButtonDisabled = true;
                        updateResearch(this.researchForm)
                            .then(res => {
                                this.researchData = JSON.parse(JSON.stringify(this.researchForm));
                                this.researchDialogVisible = false;
                                this.$router.replace({name: 'Setting', query: this.researchForm});
                                this.$message({
                                    type: 'success',
                                    showClose: true,
                                    message: res.data,
                                    center: true
                                })
                            })
                            .catch(error => {
                                this.submitButtonDisabled = false;
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }

        	,generateCode(){
        		//顺序
        		if(this.visitForm.codeFlag == '0'){
        			this.visitForm.code=this.visitForm.prefix+''+this.randomCode(this.visitForm.placeholder.length,'0')+(this.visitForm.placeholder.length>0?'1':'')
        		}else{
        		//随机
        			this.visitForm.code=this.visitForm.prefix+''+this.randomCode(this.visitForm.placeholder.length,'1')+(this.visitForm.placeholder.length>0?'1':'')
        		}
        	},
        	randomCode(n,type){
        		var rnd = "";
			    for (var i = 0; i < n-1; i++) {
			    	if(type=='0'){
			    		rnd += '0'
			    	}else{
			        	rnd += Math.floor(Math.random() * 10);
			        }
			    }
			    return rnd;
        	}
        },
        mounted(){
            this.getRecruitInfo()
        }
    }
</script>

<style scoped lang="scss">
#qrcode {
    display: block;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%,-50%);
    margin: 0 auto;
    width: 300px;
    height: 300px;
    img {
      width: 200px;
      height: 200px;
      background-color: #fff; //设置白色背景色
      padding: 6px; // 利用padding的特性，挤出白边
    }
  }
    #Setting {
        height: 100%;
        // background: rgba(73, 150, 255, 0.14);
        .setting-content {
            position: absolute;
            top: 102px;
            bottom: 16px;
            left: 16px;
            right: 16px;
            // background: #fff;
            overflow-y: auto;
            .content_coll_title {
                font-size: 16px;
                font-weight: bold;
                color: #212121;
                line-height: 24px;
                margin-left: 42px;
            }
            .content_coll_add {
                position: absolute;
                right: 10px;
                width: 96px;
                height: 32px;
                font-size: 16px;
                color: #fff;
                background: #2c5c89;
                border-color: #2c5c89 !important;
            }
            .research-data {
                padding-left: 42px;
                font-size: 16px;
                color: #212121;
                .research-data_title {
                    display: inline-block;
                    width: 100px;
                    text-align: right;
                    margin-right: 10px;
                    font-size: 15px;
                }
                .research-data_detail {
                    display: inline-block;
                    width: calc(100% - 120px);
                    vertical-align: top;
                    margin-bottom: 10px;
                    color: #777;
                    font-size: 15px;
                }
            }
            .visitForm {
                padding-left: 42px;
                color: #222222 !important;
                .clearCheck {
                    display: inline-block;
                    margin-left: 10px;
                    color: #0E84D8;
                    cursor: pointer;
                }
            }
            .visitGroup li {
                // margin-left: 10px;
                &:last-child {
                    cursor: pointer;
                    display: inline-block;
                    color: #0E84D8;
                }
                i {
                    margin-right: 10px;
                }
            }
        }
    }
</style>
<style lang="scss">
    .setting-content {
        .el-collapse-item__arrow {
            position: absolute;
            font-size: 24px;
            color: #8a8c91;
        }
        .el-icon-arrow-right{
            margin-left: 10px;
        }
        .el-collapse-item {
            margin-bottom: 10px;
        }
        .el-collapse, .el-collapse-item__header, .el-collapse-item__wrap {
            border-top: none;
        }
        .el-collapse{
            border: none;
        }
        .el-collapse-item__header {
            height: 60px;
            line-height: 60px;
            &:hover {
                background: #F5F7FA;
            }
        }
        .el-form-item__label {
            padding: 0;
            // margin-right: 10px;
            color: #212121;
            font-size: 15px;
        }
        .el-form-item__content {
            padding-left: 10px;
            margin-bottom: 10px;
        }
        .el-form-item {
            margin-bottom: 0;
            // margin-bottom: 10px;
        }
        .visitForm .el-input {
            width: 25%;
            margin-right: 10px;
        }
        .visitForm .el-select .el-input {
            width: 100%;
            // margin-right: 10px;
        }
    }
</style>